import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import '../Cards/Cards.scss'
import CategoryPageLayout from '../CategoryPageLayout/CategoryPageLayout'
// import { CategoryFeatureProps } from '../CategoryFeatures/CategoryFeature'
import { CategoryCardProps } from '../GamesCategories/CategoryCard'
import GameCard from '../GameCard/GameCard'
// import { Breadcrumb } from '../../Breadcrumbs/Breadcrumbs'
import CasualInfo from '../CategoryAndGamesInfo/CasualInfo'
import DesktopLarge from '../GameCard/DesktopLarge'
import DesktopSmall from '../GameCard/DesktopSmall'
import useShowAll from '../../../hooks/useShowAll'
import useTestimonials from '../../Testimonials/useTestimonials'
import { Faq } from '../../FAQs/faqs-list-interface'
import * as links from '../../internal-links'
import skills from '../game-skills'
import gameDescription from '../games-description'

const Casual: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "casual-intro.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      f1: file(relativePath: { eq: "casual-feature1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f2: file(relativePath: { eq: "casual-feature2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 585, layout: CONSTRAINED)
        }
      }
      f3: file(relativePath: { eq: "casual-feature3.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      cards: file(relativePath: { eq: "cards.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      trivia: file(relativePath: { eq: "trivia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 156, layout: CONSTRAINED)
        }
      }
      Carrom: file(
        relativePath: { eq: "Mobile Poster Assets/iPoster 2 Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GoPool: file(
        relativePath: { eq: "Mobile Poster Assets/GoPool_iPoster Copy.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      DotsNDash: file(
        relativePath: { eq: "Mobile Poster Assets/iPoster 2 Copy 10.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      ABCRummy: file(
        relativePath: {
          eq: "Mobile Poster Assets/iPoster_ABC_Rummy Copy 2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      Warship: file(
        relativePath: {
          eq: "Mobile Poster Assets/Warship_Iposter 392x448 Copy 2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      FruitKaat: file(
        relativePath: { eq: "Mobile Poster Assets/squarer.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 984, layout: CONSTRAINED)
        }
      }
      GoPoolDesk: file(
        relativePath: { eq: "Desktop poster assets/dd copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      FruitKaatL: file(
        relativePath: { eq: "Desktop poster assets/Banner_new_fruit3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      CarromM: file(
        relativePath: { eq: "Desktop poster assets/Mask Copy 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 720, layout: CONSTRAINED)
        }
      }
      WarshipL: file(
        relativePath: { eq: "Desktop poster assets/Warship_1440x2880.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 720, layout: CONSTRAINED)
        }
      }
      DotsNDashL: file(
        relativePath: { eq: "Desktop poster assets/iPoster 2 Copy 9.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
      ABCRummyL: file(
        relativePath: { eq: "Desktop poster assets/iPoster 2 Copy ui.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
      thFruitKaat: file(relativePath: { eq: "thumbnails/fruit-kaat.png" }) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
      thPool: file(relativePath: { eq: "thumbnails/Gopool-Thumbnails.png" }) {
        childImageSharp {
          gatsbyImageData(width: 312, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [casualFaqsSt, setCasualFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../FAQs/faqs-list').then(({ casualFaqs }) =>
      setCasualFaqsSt(casualFaqs)
    )
  }, [])

  // const features: CategoryFeatureProps[] = [
  //   {
  //     title: 'Stay hooked for hours with exciting games',
  //     imgSrc: data.f1.childImageSharp.fluid,
  //     numberColor: '#37D6C2',
  //   },
  //   {
  //     title: 'Get matched with players of your skill level',
  //     imgSrc: data.f2.childImageSharp.fluid,
  //     numberColor: '#FF3D3C',
  //   },
  //   {
  //     title: 'Play with real players. No fake profiles allowed!',
  //     imgSrc: data.f3.childImageSharp.fluid,
  //     numberColor: '#2270DA',
  //   },
  // ]

  const otherCategories: CategoryCardProps[] = [
    {
      category: 'cards',
      imgSrc: data.cards.childImageSharp.gatsbyImageData,
      description: 'From small blinds to high stakes',
    },
    {
      category: 'trivia',
      imgSrc: data.trivia.childImageSharp.gatsbyImageData,
      description: 'Fun questions in GK, 123, PicMe',
    },
  ]

  // const breadcrumbs: Breadcrumb[] = [
  //   {
  //     title: 'All Games',
  //     url: '/games',
  //   },
  //   {
  //     title: 'Casual',
  //     url: '',
  //   },
  // ]

  const isDesktop = useShowAll()
  const { casualTestimonials } = useTestimonials()

  return (
    <div id="cards-category">
      <CategoryPageLayout
        pageTitle="Use speed & strategy to win multiplayer games"
        // features={features}
        // featuresTitle="100% entertainment with our multi-player games"
        otherCategories={otherCategories}
        // breadcrumbs={breadcrumbs}
        introThumbnail={data.intro.childImageSharp.gatsbyImageData}
        livePlayersText={
          <>
            Players playing Casual games on{' '}
            <span className="underline">Mega</span> right now!
          </>
        }
        CategoryInfo={<CasualInfo />}
        testimonials={casualTestimonials.slice(0, 3)}
        faqs={casualFaqsSt}
      >
        {!isDesktop ? (
          <>
            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="GoPool"
                showName
                description={gameDescription.Pool.long}
                showDescription
                gameImage={data.GoPool.childImageSharp.gatsbyImageData}
                gameUrl={links.POOL}
              />
            </div>

            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="Carrom"
                showName
                description={gameDescription.Carrom.long}
                showDescription
                gameImage={data.Carrom.childImageSharp.gatsbyImageData}
                gameUrl={links.CARROM}
              />
            </div>

            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="ABC Rummy"
                showName
                description={gameDescription.ABCRummy.long}
                showDescription
                gameImage={data.ABCRummy.childImageSharp.gatsbyImageData}
                gameUrl={links.ABC_RUMMY}
              />
            </div>

            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="Dots & Dash"
                showName
                description={gameDescription['Dots & Dash'].long}
                showDescription
                gameImage={data.DotsNDash.childImageSharp.gatsbyImageData}
                gameUrl={links.DOTS_DASH}
              />
            </div>

            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="Warship"
                showName
                description={gameDescription.Warship.long}
                showDescription
                gameImage={data.Warship.childImageSharp.gatsbyImageData}
                gameUrl={links.WARSHIP}
              />
            </div>

            <div className="m-card">
              <GameCard
                category="casual"
                showCategory
                name="FruitKaat"
                showName
                description={gameDescription.FruitKaat.long}
                showDescription
                gameImage={data.FruitKaat.childImageSharp.gatsbyImageData}
                gameUrl={links.FRUIT_KAAT}
              />
            </div>
          </>
        ) : null}

        {isDesktop ? (
          <div className="d-cards desktop-d-cards">
            <div className="d-card d-card-full">
              <DesktopLarge
                category="casual"
                showCategory
                name="Pool"
                showName
                description={gameDescription.Pool.long}
                showDescription
                detailsBackground="#0E606C"
                gameImage={data.GoPoolDesk.childImageSharp.gatsbyImageData}
                gameUrl={links.POOL}
                gameThumbnail={data.thPool.childImageSharp.gatsbyImageData}
                skills={skills.Pool}
              />
            </div>

            <div className="d-card">
              <DesktopSmall
                category="casual"
                showCategory
                name="Carrom"
                showName
                description={gameDescription.Carrom.short}
                showDescription
                detailsBackground="#0E606C"
                gameImage={data.CarromM.childImageSharp.gatsbyImageData}
                gameUrl={links.CARROM}
              />
            </div>

            <div className="d-card">
              <DesktopSmall
                category="casual"
                showCategory
                name="ABC Rummy"
                showName
                description={gameDescription.ABCRummy.short}
                showDescription
                detailsBackground="#0E606C"
                gameImage={data.ABCRummyL.childImageSharp.gatsbyImageData}
                gameUrl={links.ABC_RUMMY}
              />
            </div>

            <div className="d-card">
              <DesktopSmall
                category="casual"
                showCategory
                name="Dots & Dash"
                showName
                description={gameDescription['Dots & Dash'].short}
                showDescription
                detailsBackground="#C96038"
                gameImage={data.DotsNDashL.childImageSharp.gatsbyImageData}
                gameUrl={links.DOTS_DASH}
              />
            </div>

            <div className="d-card">
              <DesktopSmall
                category="casual"
                showCategory
                name="Warship"
                showName
                description={gameDescription.Warship.short}
                showDescription
                detailsBackground="#B6030F"
                gameImage={data.WarshipL.childImageSharp.gatsbyImageData}
                gameUrl={links.WARSHIP}
              />
            </div>
            <div className="d-card d-card-full">
              <DesktopLarge
                category="casual"
                showCategory
                name="Fruit Kaat"
                showName
                description={gameDescription.FruitKaat.long}
                showDescription
                detailsBackground="#86369D"
                gameImage={data.FruitKaatL.childImageSharp.gatsbyImageData}
                gameUrl={links.FRUIT_KAAT}
                gameThumbnail={data.thFruitKaat.childImageSharp.gatsbyImageData}
                skills={skills.FruitKaat}
              />
            </div>
          </div>
        ) : null}
      </CategoryPageLayout>
    </div>
  )
}

export default Casual
