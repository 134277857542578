import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CasualCategory from '../../components/Games/Casual/Casual'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { GAMES, CASUAL as CASUAL_LINK } from '../../components/internal-links'
import { CASUAL } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL_LINK,
  },
]

const Casual: React.FC = () => {
  const [casualFaqsSt, setCasualFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ casualFaqs }) =>
      setCasualFaqsSt(casualFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      apkLink={CASUAL}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Best Casual Games: Play Best Casual Android Games and Win Upto 1 Cr"
        description="Download Mega to enjoy exciting casual games like Carrom, Pool, Hop Pop, Dots & Dash. Play classic casual games on android to win real cash rewards."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(casualFaqsSt)}
      />
      <CasualCategory />
    </Layout>
  )
}

export default Casual
