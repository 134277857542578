import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const CasualInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn about Casual games on Mega</h2>
      <p>
        Looking for a fun game to lift your spirits? Something to satisfy your
        gaming hunger in minutes? Something to take you back to simpler times?
        Casual games are short, engaging games that you can learn quickly and
        play any time, anywhere.
      </p>
      <p>
        Aim the striker and shoot. Point the marble and jump. The best casual
        games are inspired by real life. The tools and rules of online casual
        games are fairly straightforward. All one needs is some skill, a little
        practice, and of course, a mobile phone. So whether you are on the move,
        between meetings or just sitting on the loo, a quick casual game can add
        some colour to your life.
      </p>
      <p>
        Can you aim the cue stick right, and pot the ball in one go? Match
        shapes and colours the fastest? Fire missiles and sink enemy ships? Add
        to it the reward of real money and the thrill of playing against real
        players, and we have a recipe for an exciting challenge with non-stop
        entertainment.
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Discover the most immersive real money gaming experience on Mega. Play
        skill-based, fun casual games on Android - with an added spin of
        nostalgia. Remember the games on the last page of your school notebook?
        The games of your childhood home? Here you will find the innovative
        online multiplayer versions of your favourites, along with exciting,
        brand-new casual games. Beat your opponents and win real money. Pick
        from a number of bright fun games & start winning them with your skills.
        With 100% verified profiles & seamless, instant withdrawals, Mega is the
        leading platform for real cash casual games online.
      </p>
      <p>
        Download Mega and experience some of the best casual games on Android.
      </p>

      {/* <ul>
          <li>
            <p>Carrom</p>
          </li>
          <li>
            <p>8 Ball Pool</p>
          </li>
          <li>
            <p>Hop Pop</p>
          </li>
          <li>
            <p>ABC Rummy</p>
          </li>
        </ul> */}

      {/* <h3>Hop Pop</h3>
        <p>
          Remember Chinese Checkers? The classic star-shaped board game where
          you jump across your opponents’ marbles.
        </p>
        <p>
          Play Hop Pop on Mega, our take on the classic Chinese Checkers.
          Find the best route, jump over your opponents’ marbles to pop them,
          and be the first to occupy their house. And did we say you could win
          real cash just by hopping and popping marbles?
        </p> */}

      <h3>Dots & Dash</h3>
      <p>
        Remember those boxes on the back of your notebook — Dots and Boxes? Make
        a few right dashes, get your name in the boxes and claim your territory.
      </p>
      <p>
        Connect the dots on Mega Dots & Dash, in this real money, multiplayer
        adaptation of the classic school timepass. Choose your dots skillfully,
        mark your spot, and get to the top.
      </p>

      <h3>Carrom</h3>
      <p>
        The aim is set, the board is powdered, and the opponents are ready to
        strike - black, white and red.
      </p>
      <p>
        The classic Indian family game of carrom has been perfectly adapted for
        your mobile screen. Aim well, strike smartly, & take out the coins. Beat
        your opponents out of their money with your striking skills. Pocketing
        coins has never been this satisfying.
      </p>

      <h3>ABC Rummy</h3>
      <p>
        What if you could play Rummy, but only with English letters? Discover a
        new way to play Rummy - with your A, B and Cs. Use letters to make
        perfect sets & sequences faster than your opponents. Sweep the points
        away to win real cash. Play ABC Rummy on Mega now.
      </p>

      <h3>GoPool</h3>
      <p>
        The table is set. The players are waiting. Pool your money. Mark your
        balls. And get ready with your cue. The game of 8-Ball pool gets a
        classy multiplayer real money spin, only on Mega. Play against multiple
        players on a table of your choice. Take out the balls of your colour,
        and swipe away points quickly.
      </p>

      <h3>Fruit Kaat</h3>
      <p>
        The market is buzzing. The fruits are ripe. Slice, dice, and take the
        prize. Swipe up or down, left or right to match the fruits, and clear
        the pile faster than your opponents.
      </p>
      <p>
        Mega Fruit Kaat is a sweet take on the popular matching puzzles, with
        the added thrill of real money, played in the multiplayer mode against
        real players. Match 3 or more fruits to slice them off, and beat your
        opponents to the clock to win the prize.
      </p>

      <h3>Warship</h3>
      <p>
        Burn them before they burn you. The classic game of skillful prediction
        and masterful strategy, Warship is all about calling the right shots at
        the right time.
      </p>
      <p>
        Place your ships on your grid, and expose the location of your opponents
        by strategically attacking them. Your fleet is as powerful as your
        skills, and the challenge is good as the other players&apos; power.
        Ready for the loot, and some real cash casual gaming?
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections visible={visible} hidden={hidden} />
    </div>
  )
}

export default CasualInfo
